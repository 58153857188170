/* Here you can edit all the attributes that we decide that we end up needing */
#ExamItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#heading {
    margin-left: 4rem;
    color: black;
}

#heading h1 {
    font-size: 6rem;
}

#heading h4 {
    font-size: 2.5rem;
}