h3{
    font-size: xx-large;
    color: white;
}

label {
    color: white;
}

#patientId {
    margin-top: 20px;
}

#examId {
    margin-top: 20px;
}

#addBtn {
    background-color: rgb(108, 67, 148);
}

#addBtn:hover {
    background-color: rgb(0,0,0,0.5);
}

#randomBtn {
    background-color: rgb(67, 70, 148);
}

#randomBtn:hover {
    background-color: rgb(0,0,0,0.5);
}

#cancelBtn{
    background-color: rgb(67, 138, 148);
}

#cancelBtn:hover{
    background-color: rgb(0,0,0,0.5);
}

button{
    width: 120px;
    font-weight: 900;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
}

.formBtns {
    display: flex;
    justify-content: center;
    align-items: center;
}

.createContainer{
  
    margin: 0 auto;
    text-align: center;
    height: 60vh;
}
div.form-row{
    margin: 2rem 7rem;
}
