
.containerAdmin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
  }
  table.container{
    margin-top: 3rem;
  }
  
  table {
    width: 1000px;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
  }

  .headButton {
    background-color: transparent;
    border-color: transparent;
    width: 15px;
    position: left;
  }
  
  th, td {
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  
  th{
    text-align: center;
    vertical-align: middle;
  }
  
  tbody{
    vertical-align: middle;
  }
  
  td{
    position: relative;
  }
  img{
    height: 50px;
  }
  
  thead{
    position: sticky;
    top: 0;
  }
  
  .containerAdmin{
    display: block;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .examPageLink a{
    text-decoration: none;
    color: white;
  }
  
  td:hover{
    background-color: rgba(255,255,255,0.4);
  }
.header{
    margin-bottom: 30rem;
    margin: 3rem;
}
  .examHeader{
    font-size: 30pt;
  }

  .examSubheader{
    font-size: 20pt;
  }

  .adminButtons{
    text-align: center;
  }
  /* Temporary CSS for buttons */
  .editButton {
    display: inline-block;
    color:rgb(120, 152, 255);
    background-color: rgba(0, 0, 0, 0.2);
    border-style: none;
    padding: 1rem .5rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }

  .removeButton {
    display: inline-block;
    color: rgb(255, 127, 110);
    border-radius: 1rem;
    background-color: #4f23963a;
    padding: 1rem .5rem;
    border-style: none;
  }


#update:hover{
    background-color: rgba(77, 4, 137, 0.1);
}

.removeButton:hover{
    background-color: rgba(19, 1, 34, 0.349);
}
#createFormBtn {
  width: 100%;
  display: flex;
  justify-content: center;
}

#createFormBtn a {
  display: inline-block;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.116);
  font-weight: bold;
  transition: all ease-in-out .2s;
  padding: 5rem;
  font-size: 15pt;
  text-align: center;
  margin-top: -1rem;
  margin-bottom: -5rem;
  height: 100%;
  width: 100%;
}

#createFormBtn a:hover{
  color: white;
  background-color: rgba(0, 0, 0, 0.281);
}
  /*
  background-color: #55608f;
  */
  #headButt th {
    margin: 1rem;
  }

  #headButt th button{
    margin: 0;
    padding: 1rem;
    width: fit-content;
    text-align: left;
  }